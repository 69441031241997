<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="sliderWrap preview">
    <el-slider
      v-model="value"
      :marks="maskVal"
      disabled
      :show-tooltip="false"
      :vertical="element.style.direction === 'vertical'"
    >
    </el-slider>
  </div>
</template>

<script>
export default {
  props: ['styleDatas', 'element'],
  data() {
    return {
      sliderH: require("../assets/slider-h.png"),
      sliderV: require("../assets/slider-v.png"),
      value: 50,
      styleKeyMap: {
        circleColor: 'sliderButtonColor',
        circleSize: 'sliderButtonSize',
        trackColor: 'sliderWayColor',
        activeColor: 'sliderActiveColor',
        trackSize: 'sliderWaySize',
        fontColor: 'sliderFontColor',
        fontSize: 'sliderFontSize'
      },
      sizeKey: ['circleSize', 'trackSize', 'fontSize']
    };
  },
  watch: {
    'element.style': {
      handler: function(val) {
        this.sliderStyle();
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    maskVal: function() {
      let arr = this.element.networkConfig.sliderList;
      let unitArr = [];
      arr.forEach((item) => {
        if (!this.isEmpty(item.unit)) {
          unitArr.push(item.unit);
        }
      });
      if (unitArr && unitArr.length !== 0) {
        let obj = {};
        unitArr.forEach((item, ind) => {
          let currentPro = ind / (unitArr.length - 1) * 100;
          obj[currentPro] = item + '';
        });
        return obj;
      }

      // let min, max;
      // let unitArr = [];
      // arr.forEach((item) => {
      //   if (!this.isEmpty(item.unit)) {
      //     // 有单元值
      //     if (this.isEmpty(min) || item.unit < min) {
      //       min = item.unit;
      //     }
      //     if (this.isEmpty(max) || item.unit > max) {
      //       max = item.unit;
      //     }
      //     unitArr.push(item.unit);
      //   }
      // });
      // if (unitArr && unitArr.length !== 0) {
      //   let diff = max - min;
      //   if (diff === 0) {
      //     return { 0: unitArr[0] + ''};
      //   }
      //   // let diffUnit = diff / 100;
      //   let obj = {};
      //   unitArr.forEach((item) => {
      //     let currentPro = (item - min) / diff * 100;
      //     obj[currentPro] = item + '';
      //   });
      //   return obj;
      // }
      return {};
    }
  },
  methods: {
    isEmpty(val) {
      return val === null || val === undefined;
    },
    sliderStyle: function() {
      let style = this.element.style || {};
      for (let key in style) {
        if (this.styleKeyMap[key]) {
          let val = style[key] + (this.sizeKey.indexOf(key) !== -1 ? 'px' : '');
          this.$nextTick(() => {
            this.$el.style.setProperty(`--${this.styleKeyMap[key]}`, val); 
          });
        }
      }
      // return finalStyle;
    }
  }
};
</script>

<style lang="scss">
.sliderWrap {
  --sliderButtonColor: '#fff';
  --sliderButtonSize: 16px;
  --sliderWaySize: 6px;
  --sliderWayColor: #E4E7ED;
  --sliderActiveColor: #409EFF;
  --sliderFontSize: 14px;
  --sliderFontColor: #909399;
  .el-slider__button-wrapper {
    top: 0px;
  }
  .el-slider.is-vertical {
    height: 100%;
    .el-slider__runway {
      width: var(--sliderWaySize);
    }
    .el-slider__bar {
      width: var(--sliderWaySize);
    }
    .el-slider__button-wrapper {
      left: 0px;
    }
    .el-slider__marks-text {
      left: calc(100% + 10px);
    }
  }
  .el-slider__button {
    border: none;
    background-color: var(--sliderButtonColor);
    width: var(--sliderButtonSize);
    height: var(--sliderButtonSize);
  }
  .el-slider__stop {
    border: none;
    width: var(--sliderWaySize);
    height: var(--sliderWaySize);
  }
  .el-slider__runway {
    height: var(--sliderWaySize);
    background: var(--sliderWayColor);
  }
  .el-slider__bar {
    background: var(--sliderActiveColor) !important;
    height: 100%;
  }
  .el-slider__marks-text {
    font-size: var(--sliderFontSize);
    color: var(--sliderFontColor);
    margin-top: calc(var(--sliderWaySize) + 9px);
  }
}
</style>
<style lang="scss" scoped>
.sliderWrap {
  width: 100%;
  height: 100%;
  user-select: none;
  img {
    max-height: 100%;
    max-width: 100%;
  }
}
</style>
